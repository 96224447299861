import Vue from 'vue';
import JourneyControllerResponseModel from '@/models/JourneyController/JourneyControllerResponseModel';
import LooseObject from '@/models/Objects/LooseObject';
import store from '@/store';
import FormModel from '@/models/Objects/FormModel';
import { Form } from 'ant-design-vue';
import { AxiosInstance } from 'axios';

declare module 'vue/types/vue' {
    interface Vue {
        $axiosClient: AxiosInstance;
    }
}

export default class JourneyControllerService extends Vue {

    private merge = require('lodash.merge');
    private token: any = false;
    public loginRequired(): boolean {
        return this.$msal.requiresLogin;
    }

    public async getJourneyResponse(
        formName?: string,
        workflowName?: string,
        formData?: LooseObject,
        actionId?: number,
        resumeHeader?: LooseObject,
    ): Promise<JourneyControllerResponseModel> {

        store.state.loading = true;

        await this.getToken();

        const foundForm = formName !== undefined && workflowName !== undefined
            ? store.state.formHistory.find((form) => form.name === formName && form.workflow === workflowName)
            : undefined;
        const { action, contentType, method } = foundForm !== undefined ? foundForm : {} as any;

        const existingResponse: JourneyControllerResponseModel = {
            form: foundForm !== undefined ? foundForm : {} as any,
            formHistory: store.state.formHistory.filter((form) => !(
                form.name === formName && form.workflow === workflowName
            )),
        };

        if (this.loginRequired() && !this.token) {
            return existingResponse;
        }

        if (action !== undefined) {
            this.sendExternalRequest(action, method, formData);
            store.state.loading = false;
            return existingResponse;
        }

        const { status, returnedData } = await this.sendPrinsixRequest(
            formName,
            workflowName,
            formData,
            actionId,
            resumeHeader,
            foundForm,
        );
        store.state.loading = false;
        return (status >= 200 && status < 300)
                ? returnedData
                : existingResponse;

    }

    public async poll() {
        const { status } = await this.$axiosClient({
            url: process.env.VUE_APP_API_URL,
            method: 'HEAD',
            headers: this.authHeader(),
        });
        return { status };
    }

    private sendExternalRequest(action: string, method: string, formData: LooseObject | undefined) {
        const form = document.createElement('form');
        form.method = method;
        form.action = action;
        const actionUrl = new URL(action);

        const keys = formData !== undefined ? Object.keys(formData) : [];
        keys.forEach((key) => {
            if (key.startsWith('input')) {
                const field = document.createElement('input');
                field.type = 'hidden';
                field.name = key;
                field.value = formData![key];
                form.appendChild(field);
            }
        });

        actionUrl.searchParams.forEach((value, key) => {
            const field = document.createElement('input');
            field.type = 'hidden';
            field.name = key;
            field.value = value;
            form.appendChild(field);
        });
        document.body.appendChild(form);
        form.submit();
    }

    private async sendPrinsixRequest(
        formName?: string,
        workflowName?: string,
        formData?: LooseObject,
        actionId?: number,
        resumeHeader?: LooseObject,
        foundForm?: FormModel,
    ) {
        const requestHeaders: LooseObject = {
            accept: 'application/json',
            ...resumeHeader,
            ...this.authHeader(),
            form: formName,
        };

        const deviceIdentifier = store.state.deviceIdentifier;
        const sessionIdentifier = store.state.sessionIdentifier;
        const applicationIdentifier = store.state.applicationIdentifier;
        const applicantIdentifier = store.state.applicantIdentifier;
        const triggerName = store.state.trigger;

        if (process.env.NODE_ENV === 'development') {
            if (deviceIdentifier !== undefined) {
                requestHeaders.deviceIdentifier = deviceIdentifier;
            }
            if (sessionIdentifier !== undefined) {
                requestHeaders.sessionIdentifier = sessionIdentifier;
            }
            if (applicationIdentifier !== undefined) {
                requestHeaders.applicationIdentifier = applicationIdentifier;
            }
            if (applicantIdentifier !== undefined) {
                requestHeaders.applicantIdentifier = applicantIdentifier;
            }
        }
        if (workflowName !== undefined) {
            requestHeaders.workflow = workflowName;
        }
        if (actionId !== undefined) {
            requestHeaders.actionId = actionId;
        }
        if (triggerName !== undefined) {
            requestHeaders.trigger = triggerName;
        }

        const { status, data, headers } = await this.$axiosClient({
            url: process.env.VUE_APP_API_URL,
            method: 'POST',
            data: formData,
            headers:  requestHeaders,
        });

        data.form.sessionIdentifier = store.state.sessionIdentifier;

        const returnedData: JourneyControllerResponseModel = {
            form: data.form,
            formHistory: data.formHistory,
        };

        if (process.env.NODE_ENV === 'development') {
            store.commit('updateDeviceIdentifier', headers.deviceidentifier);
            store.commit('updateSessionIdentifier', headers.sessionidentifier);
            store.commit('updateApplicationIdentifier', headers.applicationidentifier);
            store.commit('updateApplicantIdentifier', headers.applicantidentifier);
            store.commit('updateLastModified', headers.lastModified);
        } else {
            store.commit('updateDeviceIdentifier', 'cookie');
            store.commit('updateSessionIdentifier', 'cookie');
            store.commit('updateApplicationIdentifier', 'cookie');
            store.commit('updateApplicantIdentifier', 'cookie');
            store.commit('updateLastModified', 'cookie');
        }

        if (returnedData.formHistory !== null) {
            returnedData.formHistory = returnedData.formHistory
                .filter((form) => !(form.name === formName && form.workflow === workflowName));
        }

        return { status, returnedData };
    }

    private async getToken() {
        if (this.loginRequired() && !this.token) {
            const obj = async () => {
                const t = await this.$msal.acquireToken().then((result) => result);
                this.token = t;
            };
            await obj();
        }
        return this.token;
    }

    private authHeader(): LooseObject {
        if (this.token) {
            return {
                authorization: 'Bearer ' + this.token,
            };
        }
        return {};
    }
}
